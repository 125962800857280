<template>
    <default-template>
        <v-layout justify-center align-start>
            <v-responsive width="100%" max-width="1200" class="sub-gift--list overflow-visible" :class="{ 'px-4': $vuetify.breakpoint.mobile }" >
                <slot />
            </v-responsive>
        </v-layout>

        <template slot="fab" slot-scope="{showScroll, scrollTo}">
            <slot name="fab" v-bind:showScroll="showScroll" v-bind:scrollTo="scrollTo">
            </slot>
        </template>
    </default-template>
</template>
<script>
import DefaultTemplate from "../DefaultTemplate.vue";
export default {
    components: {
        DefaultTemplate
    }
}
</script>
<style>
.sub-gift--list {
        padding:60px 0px;
}
@media (min-width:768px) {
    .sub-gift--list {
        padding:80px 0px;
    }
}
@media (min-width:1024px) {
    .sub-gift--list {
        padding:110px 0px;
    }
}

</style>

<style scoped>
.v-application p { margin: 0; }

/* COMMON */
.section { padding: 70px 0px; }

/* Custom Style */
.section3 .formmail-table { background: transparent; }
.section-title { width: 100%; position: relative; margin: -10px 0 35px; text-align: center; font-size: 37px; font-weight: 200; }
.section-title span{ color: #db1d27; font-weight: bold;}
.section-title + p { margin-top: 15px; text-align: center; font-size: 17px; }
.main-board-title { margin-bottom: 10px; line-height: 30px; font-size: 28px; color: #db1d27;}
.main-board-title a { display: block; }

@media (max-width: 1024px){
    .section-title { line-height: 18px; font-size: 23px; }
    .section-title + p { line-height: 16px; font-size: 15px; }
    .main-board-title { font-size: 20px; background-size: auto 30px; }
}
@media (max-width: 768px){
    .section-title { line-height: 15px; font-size: 20px; }
    .main-board-title { font-size: 18px; background-size: auto 25px; }
}


/* Main Board */
/* .main-board-wrap:after { content: ''; display: block; clear: both; } */
.section2{background-color: #f5f5f5;}
.section2 div { max-width: 1200px; }
.main-board-wrap{display: flex; flex-direction: row;justify-content: space-between;}
.main-board-box{width: 300px;}
.main-board-line{ width: 100%;height: 2px;background-color: #cdcdcd; margin-top: -4px;margin-bottom: 7px;}
.main-board-box .notice-title{line-height: 45px;font-size: 18px;font-weight: bold;color: #707070;}
.main-board-box .notice-date{display: none;}
.main-board-customer{display: flex; flex-wrap: wrap;}
.main-board-customer div{display: flex;width: 100%;}
.main-board-customer div:nth-child(1){margin-top: 12px;}
.main-board-customer div h5{font-size: 18px;color: #333333;padding-right: 14px;}
.main-board-customer div h6{font-size: 28px;color: #f06971;}
.main-board-customer div p{}
.main-board-customer div img{padding-right: 10px;}
.main-board-customer-div-item{align-self: center;}

.main-board-money{display: flex;flex-direction: column;width: 287px;}
.main-board-money-box1{display: flex; justify-content: space-between;}
.main-board-money-box1 div{display: flex;flex-direction: column;align-self: center;}
.main-board-money-box1 div p{font-size: 18px;color: #707070;font-weight: bold;}
.main-board-money-box2{display: flex;flex-direction: column;;margin-top: 10px;}
.main-board-money-box2 p{font-size: 18px;color: #777777;margin-left: auto;margin-bottom: 6px;}
.main-board-money-box2 img{width: 211px;margin-left: auto;}

/* Main */
.title { position: relative; text-align: center; line-height: 38px; font-size: 42px; font-weight: 200; }
.title + p { margin-top: 15px; text-align: center; font-size: 17px; }
.title-light { color: #fff !important; }
.title-light + p { color: rgba(255,255,255,.7) !important; }
.main-board-title { margin-bottom: 10px; line-height: 30px; font-size: 25px; }
.main-board-title a { display: block; background: url('/images/pinbox/main-board-title.png') no-repeat right 1px center; }

@media (max-width: 1024px){
    .title { line-height: 18px; font-size: 23px; }
    .title + p { line-height: 16px; font-size: 15px; }
    .main-board-title { font-size: 20px; background-size: auto 30px; }
}
@media (max-width: 768px){
    .title { line-height: 15px; font-size: 20px; }
    .main-board-title { font-size: 18px; background-size: auto 25px; }
    .section { padding: 70px 10px; }
}


.product-box-buttons { display: flex; align-items: center; position: absolute; top: 0; right: 0; width: 100px; box-shadow: 0px 5px 10px 0px rgba(0,0,0,.2); }
.product-box-buttons .prev,
.product-box-buttons .next { width: 50px; height: 50px; background-color: #ffffff; background-repeat: no-repeat; background-position: center; }
.product-box-buttons .prev { background-image: url('/images/main/prev.png'); }
.product-box-buttons .next { background-image: url('/images/main/next.png'); }
.product-box-buttons .prev:hover { background-color: #db1d27; background-image: url('/images/main/prev-on.png'); }
.product-box-buttons .next:hover { background-color: #db1d27; background-image: url('/images/main/next-on.png'); }

* >>> .giftcard.layout { justify-content: center; }
* >>> .giftcard.v-card { display: block; padding: 0; border: 1px solid #d2d2d2 !important; border-radius: 15px; margin-bottom: 15px; outline: none; overflow: hidden; }
* >>> .giftcard.v-card:hover { border-color: #db1d27 !important; }
* >>> .giftcard.v-card > div { border: none !important; }
* >>> .giftcard.v-card .gift-title { display: block; padding: 0 10px; margin-top: 10px; font-size: 20px; font-weight: 500; }
* >>> .giftcard.v-card .gift-subtitle { padding: 0 10px; }
* >>> .giftcard.v-card__price { display: block; padding-bottom: 8px; line-height: 1; font-size: 22px; font-weight: 700; border-bottom: 1px solid #eeeeee; }
* >>> .giftcard.v-card__price em { font-style: normal; }
* >>> .giftcard.v-card__price small { font-size: 18px; color: #f55b5b; font-weight: 800; }
@media(max-width:1024px){
	.product-box-buttons { display: none; }
}
@media(max-width:768px){

}

</style>